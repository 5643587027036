<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    class="q-pa-md justify-center"
    position="bottom"
  >
    <q-card
      class="my-card text-black q-ma-md"
      :style="{ 'font-family': settings.typography }"
    >
      <q-card-section class="justify-between row q-pt-md q-pb-xs">
        <div class="text-subtitle1">
          {{ $t("BuyingProcess.products.pickup-store") }}
        </div>
      </q-card-section>

      <q-card-section class="q-pa-xs">
        <div v-if="shops.length > 0" class="q-pa-xs">
          <q-list v-for="shop in shops" :key="shop.id">
            <q-item tag="label" v-ripple>
              <q-item-section>
                <q-item-label class="text-grey">{{ shop.name }}</q-item-label>
                <q-item-label caption class="text-black">{{
                  shop.address
                }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-radio
                  :style="{ color: settings.color_accent }"
                  v-model="chosenOption"
                  :val="shop"
                  @click="sendStoreAddress()"
                  v-close-popup
                />
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <q-item-label caption v-else class="text-black q-ml-md"
          > {{ $t("BuyingProcess.products.no-shops") }}</q-item-label
        >
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import settings from "@/mixins/settings";

export default {
  mixins: [settings],
  props: {
    dialog: {
      default: false,
    },
    product: {
      type: Object,
    },
    points: {
      type: Object
    },
  },
  setup() {
    return {
      maximizedToggle: true,
      chosenOption: ref(),
    };
  },
  computed: {
    shops() {
      return this.$store.state.shops.shops;
    },
  },
  methods: {
    ...mapActions("shops", ["getShops"]),

    // Envía un emit con la opción escogida
    sendStoreAddress() {
      this.$emit("passOptionStore", this.chosenOption);
    },
  },
  mounted() {
    this.getShops();
   },
};
</script>

<style lang="scss" scoped>
.q-card {
  height: 50vh;
}

.q-radio {
  display: flex;
  justify-content: space-between;
  font-size: 50px;
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
    position: relative;
    left: 26%;
    min-width: 466px;
    top: 0px;
    min-height: 300px;
    border-radius: 25px 25px 25px 0px !important;
  }
}

.q-dialog__inner--maximized > div {
  border-radius: 25px 25px 25px 0px !important;
}
</style>
